import { useState } from "react";
import ListOfCharacters from "../Components/ListOfCharacters";
import ListOfStoryboards from "../Components/ListOfStoryboards";
import Masthead from "../Components/Masthead";
import NavigationButton from "../Components/NavigationButton";
import SignIn from "../Components/SignInCard";
import SignUp from "../Components/SignUpCard";
import './Home.css'
import ConfirmModal from "../Components/ConfirmModal";
import ForgotPasswordCard from "../Components/ForgotPasswordCard";
import PurchaseTokensCard from "../Components/PurchaseTokensCard";
import useInactivityTimeout from "../Utilities/useInactivityTimeout"
import Footer from "../Components/Footer";


const Home = ({characters, setCharacters, storyboards, setStoryboards, setStoryboardState, signUpInfo, setSignUpInfo, isAuthenticated, setIsAuthenticated, userInfo, setUserInfo}) => {
  const homePageNonAuthImage = require('../Images/StoryboardGenerator_NonAuth_HomePage_Image1.png')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [modalFunctionParams, setModalFunctionParams] = useState()
  const [modalFunction, setModalFunction] = useState()
  useInactivityTimeout(21600000, 'cooppStoryboardGenerator_AuthToken');
  
  return (
    <div className="App">
      <Masthead isAuthenticated={isAuthenticated} signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo} setIsAuthenticated = {setIsAuthenticated} userInfo={userInfo} setUserInfo={setUserInfo}/>
      <SignUp signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo}/>
      <SignIn signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo} setIsAuthenticated = {setIsAuthenticated}/>
      <ForgotPasswordCard signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo} setIsAuthenticated = {setIsAuthenticated}/>
      <PurchaseTokensCard userInfo = {userInfo} />
      {isAuthenticated ? 
      <body className="App-body-home">
      <div className="background-shades" onClick={(e)=>{e.target.style.display='none'; document.getElementsByClassName("sign-in-card")[0].style.display='none'; document.getElementsByClassName("sign-up-card")[0].style.display='none'; document.getElementsByClassName("forgot-password-card")[0].style.display='none';document.getElementsByClassName("purchase-tokens-card")[0].style.display='none'; document.getElementsByClassName('sign-in-notification-message')[0].innerHTML=''; document.getElementsByClassName('sign-up-notification-message')[0].innerHTML=''; }}/>
      {isModalOpen ? <ConfirmModal message={modalMessage} onConfirm={()=>{modalFunction(modalFunctionParams); setIsModalOpen(false)}} onCancel={()=>setIsModalOpen(false)}/> : <p></p>}
        <div className="User-Dashboard">
          <div className="Storyboard-Dashboard">
            <div className="Dashboard-Header">
              <h3>Storyboards</h3>
              <NavigationButton buttonTitle="New Storyboard" setStoryboardState={setStoryboardState} storyboards={storyboards} setStoryboards={setStoryboards} isAuthenticated={isAuthenticated} />
            </div>
            <ListOfStoryboards storyboards={storyboards} setStoryboards={setStoryboards} setStoryboardState={setStoryboardState} setModalFunctionParams = {setModalFunctionParams} setModalFunction={setModalFunction} setModalMessage = {setModalMessage} setIsModalOpen={setIsModalOpen}/>
          </div>
          <div className="Characters-Dashboard">
            <div className="Dashboard-Header">
            <h3>Characters</h3>
            <NavigationButton buttonTitle="New Character" isAuthenticated={isAuthenticated}/>
            </div>
            <ListOfCharacters characters={characters} setCharacters={setCharacters} setModalFunctionParams = {setModalFunctionParams} setModalFunction={setModalFunction} setModalMessage = {setModalMessage} setIsModalOpen={setIsModalOpen} />
          </div>
        </div>
      </body> :
<body className="App-body">
<div className="background-shades" onClick={(e)=>{e.target.style.display='none'; document.getElementsByClassName("sign-in-card")[0].style.display='none'; document.getElementsByClassName("sign-up-card")[0].style.display='none'; document.getElementsByClassName('sign-in-notification-message')[0].innerHTML=''; document.getElementsByClassName('sign-up-notification-message')[0].innerHTML=''; }}/>
  <div className="User-Dashboard-NotSignedIn">
    <div className="homepage-nonauth-leftHalf">
    <img alt="Collage of images, illustrating the potential of NXTVid's Storyboard Generator tool." className="homepage-nonauth-image" src={homePageNonAuthImage}/>
    </div>
    
    <div className="homepage-nonauth-righHalf">
      <h3 className="homepage-nonauth-title">Storyboard Generator</h3>
      <h5 className="homepage-nonauth-subtitle">Bring your ideas to life using NXTVid's Storyboard Generator.</h5>
      <div className='signup-signin-homepage'>
                <p className='Homepage-SignIn' onClick={()=>{document.getElementsByClassName('sign-in-card')[0].style.display='block';document.getElementsByClassName('background-shades')[0].style.display='flex';}}>Sign In</p>
                  <button id="Homepage-SignUp" className='Homepage-SignUp' onClick={()=>{document.getElementsByClassName('sign-up-card')[0].style.display='block';document.getElementsByClassName('background-shades')[0].style.display='flex';}}>Join</button>
                </div>
    </div>
    
  </div>
</body>
    
    }
     <footer>
      <Footer/>
      </footer> 
    </div>
  );
};

export default Home;
